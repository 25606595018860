<template>
    <div class="add-new-holiday">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.holidayUpdate')"
            :title="$t('messages.holidayUpdate')"
            @submitPressed="submitPressed"
        />
        <holiday-form
            v-if="holiday"
            :holidayObject="holiday"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import HolidayForm from "./components/HolidayRequestsForm.vue";

export default {
    components: {
        TitleBar,
        HolidayForm,
    },
    data() {
        return {
            holiday: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$HolidayRequests.getResource({id}).then((response) => {
                this.holiday = response.data;
                this.holiday.fromDate = new Date(response.data.fromDate)
                this.holiday.toDate = new Date(response.data.toDate)
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
